@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .box-border-1 {
    @apply border-[1px] border-solid;
  }
}

/* globals.css */
.text-gradient {
  @apply bg-text-gradient bg-clip-text text-transparent;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.tw-btn {
  @apply rounded-[48px] px-3 py-2 bg-main-red text-main-black text-center;
}

.scrollbar-hide {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.scrollbar-hide::-webkit-scrollbar {
  display: none; /* Chrome, Safari, and Opera */
}
